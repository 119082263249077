import {Button, Form} from "react-bootstrap";

export function OvpnDetails(props) {
    const vpnConfig = props?.ovpnConfig;

    if (vpnConfig == null) {
        return null;
    }

    return (<>
        <br/><br/>
        <Form.Label>Certificate CN</Form.Label>
        <Form.Control defaultValue={vpnConfig.cn} readOnly={true}/>
        <br/>
        <Form.Label>Created</Form.Label>
        <Form.Control defaultValue={vpnConfig.created} readOnly={true}/>
        <br/>
        <Form.Label>Expires</Form.Label>
        <Form.Control defaultValue={vpnConfig.expires} readOnly={true}/>



    </>);
}