import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import {createContext, useMemo, useState} from "react";
import {Outlet, Route, Routes} from "react-router-dom";


import VpnHeader from "./VpnHeader";
import {Ovpn, OvpnReq} from "./Ovpn";
import {MfaVerificate} from "./Mfa";
import {useNavigate} from "react-router";
import {Button} from "react-bootstrap";

export const UserContext = createContext({
    userObj: null,
    setUserObj: () => {
    }
});


function NoMatch() {
    return (<>
        <strong>404 Not Found</strong><br/>
        <img src="/404.jpg" className="responsiveImage"/>
    </>);
}

function Home() {
    return (<>
        Welcome to Ultinous' Self-Service System
    </>);
}


function App() {
    const [user, setUser] = useState(null);
    const [userObj, setUserObj] = useState(null);
    const userContext = useMemo(() => ({userObj, setUserObj}), [userObj]);

    return (
        <div className="App">
            <UserContext.Provider value={userContext}>
                <VpnHeader/>
                <div className={"myRoot"}>
                    <Outlet/>


                    <Routes>
                        <Route path="/">}>
                            <Route index element={<Home/>}/>
                            <Route path="/page/ovpn" element={<Ovpn/>}/>
                            <Route path="/page/ovpnReq" element={<OvpnReq/>}/>
                            <Route path="/page/mfaVerificate" element={<MfaVerificate/>}/>
                            <Route path="*" element={<NoMatch/>}/>
                        </Route>
                    </Routes>
                </div>
            </UserContext.Provider>
        </div>
    );
}

export default App;
