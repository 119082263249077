import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {requestMfa, verifyMfa} from "./ApiRequests";
import {emitCustomEvent} from "react-custom-events";
import {EventType} from "./Constants";
import {Button, Form} from "react-bootstrap";
import {UserContext} from "./App";

export function MfaVerificate() {
    const [mfaValue, setMfaValue] = useState("");
    const {userObj, setUserObj} = useContext(UserContext);
    const mfaLength = 6;
    let navigate = useNavigate();


    const inputChange = (evt) => {
        const input = evt.target.value;
        console.log(evt)
        if (input.length === mfaLength) {
            setMfaValue(input);
            verifyMfa(parseInt(input, 10)).then(value => {
                if (value[0] === 204) {
                    emitCustomEvent(EventType.toast_PrintMessage, {text: "Successful MFA challange", hide: 8000});
                    window.sessionStorage.mfa_completed = true;

                    navigate("/page/ovpn"); //TODO: Move to prop
                } else if (value[0] === 401) {
                    emitCustomEvent(EventType.toast_PrintMessage, {
                        text: "Invalid MFA entered",
                        hide: 8000,
                        variant: "warning"
                    });
                    setMfaValue("");
                } else {
                    emitCustomEvent(EventType.toast_PrintMessage, {
                        text: "Error in verification " + value[0] +
                            "\n" + value[1], variant: "danger"
                    });
                    navigate("/");
                }
            })
        }
        setMfaValue(evt.target.value);

    }

    return (
        <>
            <Form>
                <Form.Label>SMS Code</Form.Label>
                <Form.Control type="number" value={mfaValue} onChange={(evt) => {
                    inputChange(evt)
                }}/> <br/>

            </Form>

        </>)

}

export function MfaReq() {
    const {userObj, setUserObj} = useContext(UserContext);
    const [buttonText, setButtonText] = useState("Click here to request SMS code")
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();
    const mfaRequestButtonClick = (evt) => {
        setButtonText("Request sent");
        setIsLoading(true);
        requestMfa().then(value => {
            if (value[0] == 200) {
                emitCustomEvent(EventType.toast_PrintMessage, {
                    text: "SMS sent to phone ending with " + value[1].phone_ending,
                    hide: 10000
                });
                window.sessionStorage.mfaSession = value[1].mfaSession;
                window.sessionStorage.mfaExpire = value[1].mfaExpire;
                window.sessionStorage.mfa_completed = false;

                navigate("/page/mfaVerificate");
            } else {
                emitCustomEvent(EventType.toast_PrintMessage, {
                    text: "Error while sending SMS code " + value[0] +
                        "\n" + value[1], variant: "danger"
                });
                navigate("/");

            }
        });

    }
    useEffect(() => {
        if (userObj?.mfaSession) {
            navigate("/page/mfaVerificate");
        }
    });
    return (<>
        <span>2 factor authentication is needed.</span>
        <Button onClick={event => {
            mfaRequestButtonClick(event)
        }} disabled={isLoading}>{buttonText}</Button>

    </>);
}