import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {getOvpnConfig, revokeOvpn} from "./ApiRequests";
import {emitCustomEvent} from "react-custom-events";
import {EventType} from "./Constants";
import {keycloakCtx} from "./keycloakHandler";
import {Button, Modal, Spinner} from "react-bootstrap";
import {OvpnDetails} from "./OvpnDetails";
import {UserContext} from "./App";
import {OvpnMfaOK} from "./OvpnMfaOK";
import {MfaReq} from "./Mfa";

export function OvpnReq() {

    const {userObj, setUserObj} = useContext(UserContext);

    return (
        <>
            {window.sessionStorage?.mfa_completed ? <OvpnMfaOK/> : <MfaReq/>}
        </>);
}

function ConfirmRevokeOvpn(props) {

    const [show, setShow] = useState(props.show);

    const handleClose = (event) => {
        props.cancel();
    }

    const confirmBtnClick = (event) => {
        props.confirm();
    }

    return (<> <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm destructive action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            This will revoke your OpenVPN certificate. Can't be undone. Continue?
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Cancel
            </Button>
            <Button variant="danger" onClick={confirmBtnClick}>Understood, proceed</Button>
        </Modal.Footer>
    </Modal>
    </>);
}


export function Ovpn() {
    const {userObj, setUserObj} = useContext(UserContext);
    const [status, setStatus] = useState("LOADING");
    const [ovpn, setOvpn] = useState();
    const [showConfirm, setShowConfim] = useState(false);
    let navigate = useNavigate();

    const revokeBtn = (event) => {
        setShowConfim(true);
    }

    const revokeCb = () => {
        setShowConfim(false);
        console.log("confirmed");
        revokeOvpn().then(value => {
            if (value[0] === 204) {
                emitCustomEvent(EventType.toast_PrintMessage, {text: "OpenVPN revoked", hide: 8000});
                navigate("/");

            } else {
                emitCustomEvent(EventType.toast_PrintMessage, {
                    text: "Error while revoking.\n Code " + value[0] +
                        "\n" + value[1], variant: "danger"
                });
            }
        });

    }
    const reqOvpnBtn = (evt) => {
        navigate("/page/ovpnReq");
    }

    useEffect(() => {
        if (keycloakCtx.authenticated) {
            initOvpn();
        } else {
            keycloakCtx.onReady = initOvpn;
        }

    }, []);

    const initOvpn = (auth) => {
        if (ovpn == null) {
            console.log("getovpnconfig");

            getOvpnConfig().then(value => {
                if (value[0] === 200) {
                    setStatus("NONEMPTY");
                    setOvpn(value[1]);
                    console.log("asd")
                } else if (value[0] === 404) {
                    setStatus("EMPTY");
                    console.log("asd2")
                } else {
                    setStatus("ERROR");
                    emitCustomEvent(EventType.toast_PrintMessage, {
                        text: value[1],
                        hide: 0,
                        variant: "danger"
                    });
                    console.log("APIERROR", value[1]);
                }
            })
        }
    }


    return (<>
            {status === "LOADING" && <><Spinner animation="border"/> Loading</>}
            {status === "EMPTY" && <>You currently do not have OpenVPN.<br/> <br/> <Button
                onClick={reqOvpnBtn}> Request </Button></>}
            {status === "NONEMPTY" && <><OvpnDetails ovpnConfig={ovpn}/>
                <br/>
                <Button onClick={revokeBtn} variant="outline-danger">Revoke</Button>
                <ConfirmRevokeOvpn show={showConfirm} confirm={revokeCb} cancel={() => {
                    setShowConfim(false)
                }}/> </>
            }

        </>
    );
}