import {useContext, useEffect, useState} from "react";
import {Alert, Button, Container, Nav, Navbar, NavDropdown, Toast} from "react-bootstrap";

import {UserContext} from "./App.js";
import Keycloak from "keycloak-js";
import {Link, Outline} from "react-router-dom";
import {emitCustomEvent, useCustomEventListener} from 'react-custom-events';
import {EventType} from "./Constants";
import {keycloakCtx} from "./keycloakHandler";


function VpnHeader() {
    const [user, setUser] = useState(null);
    const {userObj, setUserObj} = useContext(UserContext);


    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">USSrencccer</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                            <Nav.Link as={Link} disabled={!userObj} to="/page/ovpn">OpenVPN Config</Nav.Link>
                        </Nav>
                        <LoggedInUser/>

                    </Navbar.Collapse>
                </Container>
            </Navbar>


        </div>
    );
}

function ToastHandler(props) {
    const [toastMessage, setToastMessage] = useState();
    const [show, setShow] = useState(false);
    const [variant, setVariant] = useState("success");
    const [hide, setHide] = useState(0);

    useCustomEventListener(EventType.toast_PrintMessage, data => {
        setToastMessage(data.text);
        setVariant(data.variant ?? "success");
        setHide(data.hide ?? 0);
        setShow(true);
    });

    const doHide = () => {
        setShow(false);
    }

    useEffect(() => {
        if (hide !== 0) {
            setHide(0); //zero out so next event will re-trigger the useEffect
            setTimeout(() => {
                doHide()
            }, hide);
        }
    }, [hide]);

    const doShow = () => {
        setShow(true);
    }

    return (
        <Alert className={"loginAlert"} show={show} onClick={event => {
            doHide()
        }} dismissible variant={variant}>
            {toastMessage}
        </Alert>
    )
}

function LoggedInUser() {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [isLoading, setLoading] = useState(true);
    const {userObj, setUserObj} = useContext(UserContext);



    useEffect(() => {
        window.userObj = userObj
        console.log("useffectstart")
        if (!!userObj) return;
        console.log("useffectstar2t")

        keycloakCtx.initLogin(true).then( () => {
            if(keycloakCtx.authenticated){
                console.log('authed',keycloakCtx     );
                setUserObj({username: keycloakCtx.idTokenParsed?.name});
            }
            setLoading(false)
        })


    }, []) //TODO: renew token


    const loginUser = event => {
        console.log(1);
        setLoading(true);
        keycloakCtx.initLogin().then(auth => {
            console.log("nyeeeh?", keycloakCtx)
            if (auth) {
                setUserObj({username: keycloakCtx.idTokenParsed?.name});
            }
            setLoading(false);
        })
    };


    if (userObj == null) {
        return (
            <>
                <ToastHandler showToast={showToast} setShowToast={setShowToast} toastMessage={toastMessage}/>
                <Navbar.Text>
                    <Button onClick={event => loginUser(event)} disabled={isLoading}>{isLoading?"Loading...":"Login"}</Button>
                </Navbar.Text>
            </>
        )
    } else {
        return (
            <>
                <ToastHandler showToast={showToast} setShowToast={setShowToast} toastMessage={toastMessage}/>
                <Navbar.Text onClick={event => setUserObj(null)}>
                    Signed in as: <a href="#">{userObj.username}</a> <Button id="logoutBtn">Logout</Button>
                </Navbar.Text>
            </>
        )
    }

}

export default VpnHeader;