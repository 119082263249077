import Keycloak from "keycloak-js";
import {KEYCLOAK_CLIENTID, KEYCLOAK_REALM, KEYCLOAK_URL} from "./Constants";

let keycloakCtx = new Keycloak({url: KEYCLOAK_URL, realm: KEYCLOAK_REALM, clientId: KEYCLOAK_CLIENTID});

const refreshToken = function () {
    keycloakCtx.updateToken(60)
}

const ensureTokenValidity = async function() {
    await keycloakCtx.updateToken(5);
}

keycloakCtx.initLogin = function (background=false) {
    keycloakCtx.onTokenExpired = refreshToken
    console.log('initLogin');
    if (background) {
        return keycloakCtx.init({onLoad: 'check-sso', checkLoginIframe: true, enableLogging: true}).then(auth => {
            if (auth) {
                console.log('first ini', keycloakCtx);
                window.keycloakCtx = keycloakCtx;
            }
        })
    } else {
        return keycloakCtx.init({onLoad: 'login-required', checkLoginIframe: true})
    }


}


export {keycloakCtx, ensureTokenValidity};