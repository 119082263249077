import axios from "axios";
import {emitCustomEvent, useCustomEventListener} from 'react-custom-events';
import {EventType} from "./Constants";
import {ensureTokenValidity, keycloakCtx} from "./keycloakHandler";
import {APIURL} from "./Constants";


const getMfaSession = () =>{
  if(window.sessionStorage?.mfaSession){
      const mfaExpire = Date.parse(window.sessionStorage.mfaExpire);
      if(isNaN(mfaExpire)){
          throw('Invalid date');
      }
      if(mfaExpire < Date.now()){
          window.sessionStorage.removeItem('mfaExpire');
          window.sessionStorage.removeItem('mfaSession');
          window.sessionStorage.removeItem('mfa_completed');
          return null;
      }
      return window.sessionStorage.mfaSession;
  }
};

const requestMfa = async () => {
    await ensureTokenValidity();
    const token = keycloakCtx.token;
    const headers = {'Authorization': 'Bearer ' + token};
    try {
        let resp = await axios.post(APIURL + "/sendsmsverification", {}, {headers});
        return [resp.status, resp.data];
    } catch (e) {
        return [500, "Can't reach API"];
    }

}

const verifyMfa = async (mfaToken) => {
    await ensureTokenValidity();
    const token = keycloakCtx.token;
    const headers = {'Authorization': 'Bearer ' + token, "mfaSession": getMfaSession()};
    if (isNaN(mfaToken)) {
        return [500, "Not a number"];
    }
    try {
        let resp = await axios.post(APIURL + "/verifysmstoken", {mfaToken}, {headers, validateStatus: () => true});
        return [resp.status, resp.data];
    } catch (e) {
        return [500, "Can't reach API"];
    }
}

function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
}

const createOvpnConfig = async () => {
    await timeout(3000);
    await ensureTokenValidity();
    const token = keycloakCtx.token;
    const headers = {'Authorization': 'Bearer ' + token, "mfaSession": getMfaSession()};
    try {
        let resp = await axios.post(APIURL + "/openvpn", {}, {headers, validateStatus: () => true});
        console.log(resp.data)
        return [resp.status, resp.data];
    } catch (e) {
        return [500, "Can't reach API"];
    }
}

const getOvpnConfig = async () => {
    await ensureTokenValidity()
    const token = keycloakCtx.token;
    console.log(token);
    const headers = {'Authorization': 'Bearer ' + token};
    try {
        let resp = await axios.get(APIURL + "/openvpn", {"headers": headers, validateStatus: () => true});
        console.log(resp.data)
        return [resp.status, resp.data];
    } catch (e) {
        return [500, "Can't reach API"];
    }
}

const revokeOvpn = async () => {
    await ensureTokenValidity();
    const token = keycloakCtx.token;
    const headers = {'Authorization': 'Bearer ' + token};
    try {
        let resp = await axios.delete(APIURL + "/openvpn", {"headers": headers, validateStatus: () => true});
        console.log(resp.data)
        return [resp.status, resp.data];
    } catch (e) {
        return [500, "Can't reach API"];
    }
}


export {requestMfa, verifyMfa, createOvpnConfig, getOvpnConfig, revokeOvpn}