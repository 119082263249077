export class EventType {

    static toast_PrintMessage = "toast:PrintMessage";

}

const def_api_url = (window.location.hostname === "localhost") ?
    "http://localhost:5000" : window.location.protocol + "//" + window.location.host + "/API";
const api_override = "@@API_URL@@";
export const APIURL = (api_override.startsWith("@@") ? def_api_url : api_override);
export const KEYCLOAK_URL = 'https://idp.ultinous.com/auth';
export const KEYCLOAK_REALM = 'ultinous';
export const KEYCLOAK_CLIENTID = 'dancsa_test';