import {useContext, useState} from "react";
import {Alert, Button, Form, Spinner} from "react-bootstrap";
import {createOvpnConfig} from "./ApiRequests";
import {UserContext} from "./App";
import {OvpnDetails} from "./OvpnDetails";
import {saveAs} from "file-saver";


export function OvpnMfaOK() {
    const [status, setStatus] = useState("IDLE");
    const reqButtonDef = "Require VPN configuration";
    const [reqButton, setReqButton] = useState(reqButtonDef);
    const [reqButtonDisabled, setReqButtonDisabled] = useState(false);
    const {userObj, setUserObj} = useContext(UserContext);
    const [vpnConfig, setVpnConfig] = useState();

    const reqOvpn = event => {
        setStatus("FETCHING");
        setReqButton(<><Spinner animation="grow" size="sm"/> Loading</>);
        createOvpnConfig()
            .then(value => {
                setReqButton(reqButtonDef);
                if (value[0] === 200) {
                    setStatus("DONE");
                    setVpnConfig(value[1]);
                    setReqButtonDisabled(true);
                } else {
                    setStatus("ERROR");
                    //FIXME: could be better
                    setVpnConfig(`Error. HTTP code ${value[0]} \n ${value[1]} `);
                }
            })
    };


    const downloadConfig = event => {
        var blob = new Blob([vpnConfig.config], {type: "text/plain;charset=utf-8"});
        (async () => {
            saveAs(blob, "ultinous.ovpn")
        })();
    };

    return (<>
        <Button onClick={reqOvpn} disabled={["DONE", "FETCHING"].includes(status)}>{reqButton}</Button>
        <br/> <br/>

        <Form.Label>OpenVPN Configuration</Form.Label>
        <Form.Control as="textarea" className="ovpnConfig" value={vpnConfig?.config ?? vpnConfig} readOnly/>
        {status === "DONE" && <OvpnDetails ovpnConfig={vpnConfig}/>}
        <br/>
        {status === "DONE" && <Alert variant="warning">The server does not store your private key so this configuration is shown only once.
            Make sure you save it to a sufficiently secure place!</Alert>}

        {status === "DONE" && <Button onClick={downloadConfig}>Save config to file</Button>}


    </>);
}

